import '../phpafrontend/sass/style.scss'
import $ from 'jquery'

function clearBookmark () {
  let today = new Date()
  $('li.expiring-link').each(function (index) {
    let expireAt = new Date($(this).attr('data-active-until'))
    if (expireAt < today) {
      $(this).hide()
    }
  })
}

clearBookmark()
setInterval(function () {
  clearBookmark()
}, 1000 * 60 * 60)
